/**
 * Constants
 *
 * @description general constants
 */
export const Constants = {
  MOCK_FLAG: 'mockRequest',
  BASE_URL_PRODUCTS_IMAGE: 'https://pedidosya.dhmedia.io/image/pedidosya/products',
  BASE_URL_VENDOR_IMAGE: 'https://pedidosya.dhmedia.io/image/pedidosya/restaurants',
  GROCERY_PATH_DL: 'pedidosya://shop_detail/{vendorId}',
  PEYA_PLUS_ICON: 'https://pedidosya.dhmedia.io/image/pedidosya/order-management/peya-plus.png',
  FALLBACK_PRODUCT_ICON:
    'https://pedidosya.dhmedia.io/image/pedidosya/order-management/fallback_product.png',
  SHOW_SWIPE_CONTROLS_WINDOW_WIDTH: 1024,
  ORIGIN_OS: 'orderStatus',
  STORE_SHOW_PRODUCTS: 1,
  STORE_DONT_SHOW_PRODUCTS: 2,
};
