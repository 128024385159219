import PropTypes, { bool } from 'prop-types';
import React, { useContext, useEffect, useState, createContext } from 'react';
import { HttpClient } from '@app/utils/http';
import { Urls } from '@app/config/urls';
import { handleItem } from '@app/utils/storage';
import { Constants } from '@app/config/constants';
import { ErrorBoundary } from '@app/components/ErrorBoundary';
import { PageLoading } from '@app/components/PageLoading';
import { getWindow } from '@app/utils/window';

const PublicEnvContext = createContext();
export const getPublicEnv = async () => {
  const http = HttpClient.getInstance();
  const { data } = await http.get(Urls.ENV);
  handleItem(Constants.MOCK_FLAG, data.mock);
  return data;
};

export const usePublicEnv = () => {
  return useContext(PublicEnvContext);
};

export const PublicEnvProvider = ({ children, context, getPublicEnv, pWebView = false }) => {
  const [publicEnv, setPublicEnv] = useState(context);
  const [error, setError] = useState(null);

  if (pWebView) {
    /**
     * To allow load the parcel in webview web environemt
     * used by order status team
     */
    const wn = getWindow();
    const basePath = wn ? wn.location.pathname : '';
    HttpClient.getInstance(basePath.replace(/\/$/, ''));
  }

  useEffect(() => {
    if (publicEnv) {
      handleItem(Constants.MOCK_FLAG, publicEnv.mock);
      return;
    }
    getPublicEnv().then(setPublicEnv).catch(setError);
  }, [getPublicEnv, publicEnv]);

  if (error) {
    return <ErrorBoundary error={error} />;
  }

  if (!publicEnv) return <PageLoading />;

  return <PublicEnvContext.Provider value={publicEnv}>{children}</PublicEnvContext.Provider>;
};

export const publicEnvShape = {
  locale: PropTypes.string.isRequired,
  device: PropTypes.string.isRequired,
  environment: PropTypes.string.isRequired,
  country: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
  isParcel: PropTypes.bool,
  mock: PropTypes.bool,
  deviceOS: PropTypes.string,
  appVersion: PropTypes.string,
  userId: PropTypes.number,
  user: PropTypes.object,
  sessionId: PropTypes.string,
  revamp: PropTypes.bool,
};
